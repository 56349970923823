@font-face {
    font-family: Aileron;
    src: url("../fonts/Aileron-Regular.otf") format("opentype");
}

@font-face {
    font-family: Aileron;
    font-weight: bold;
    src: url("../fonts/Aileron-Black.otf") format("opentype");
}


body,
h1,h2,h3,h4,h5,h6,
p,
input,
button,
textarea{
    font: 500 1.4rem 'Aileron';
    font-family: 'Aileron', monospace;
}
h1{
    font-size: 2.5em;
}
h2{
    font-size: 1.6em;
}
p{
    font-size: 0.7em;
}

.bg-background-img{
    background-image: url('../../../src/assets/images/background.png');
    background-repeat: no-repeat;
    background-size: 100% 57%;
}
.bg-background-img-form{
    background-image: url('../../../src/assets/images/background-form.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment:fixed;
    min-height: 100vh;
}
.bg-background-img-consum-2{
    background-image: url('../../../src/assets/images/background-consum2.png');
    background-repeat: no-repeat;
    background-size: 100% 57%;
    background-position-y: 159%;
}
.img-consum{
    height: auto;
}
.img-chart{
    width: auto;
}
.img-consum-bcn{
    height: 100%;
}
.content-consum{
    opacity: 0.75;
    max-width: 286.7px;
    width: 100%;
}

.content-project{
    border-radius: 18px;
    width: 100%;
    height: 100%;
}
.content-chart{
    width: 48%;
    height: auto;
}

.w-img-project{
    height: 140.21px;
    width: 140.16px;
}
.circle-yellow{
    background-color: #FDC540
}
.circle-blue{
    background-color: #7BBDFF
}
.circle-brown{
    background-color: #BDB792
}
.circle-black{
    background-color: #E0E0E0
}
.geolookup__input--nominatim>input,
input[type=text],
input[type=number],
input[type=email],
input[type=date],
select {
    border: 1px solid!important;
    border-color:  #A0BD80!important;
    background: #F2EFE1;
    color: black;
    box-shadow: none!important;
    border-radius: 10px!important;
    width: 100%;
    height: 50px;
}
input::placeholder{
    text-transform: uppercase;
}
.geolookup__suggests{
    background: white;
    cursor: pointer;
}
.geolookup__suggests li{
    padding: 4px 2px;
}
._3Ewis>img {
    top: 12px!important;
    width: 22px!important;
}
.more-transport{
    width: 50px;
    height: 50px;
    background-color:  #A0BD80;
    border-radius: 8px;
    color: #E1DFD5;
    font-size: 35px;

}
.leaflet-routing-alt,
.hidden{
    display: none;
}

nav > div.language{
    width: 59px;
    height: 59px;
    border-color: #408A40;
    padding-top: 14px;
}

.img-project{
    width: 160px;
    height: 160px;
}
.div-consum{
    height: 120px;
}

.App-header{
    /*margin-bottom: 10px;*/
}

.error p{
    color: red;

}
