
/* tablets IPAD-AIR */
@media only screen and (max-width: 1200px) {
    .title-header,
    .title-form{
        font-size: 1.3em;
    }
    .button-calcula{
        display: block;
    }
}
/* tablets */
@media only screen and (max-width: 1024px) {
    .title-header,
    .title-form{
        font-size: 1.2em;
    }
    .content-consum{
        width: 100%;
        padding: 10px 25px;
    }
    .img-consum{
        width: auto;
        height: auto;
        max-height: 120px;
    }
    .img-consum-bcn{
        width: 50px;
    }
    .awssld__content{
        overflow-y: auto!important;
    }
    .bg-background-img-consum-2{
    }
    .awssld__content{
        background-color: #E1DFD4!important;
    }
    h2{
        font-size: 1.2em;
    }
}

/* TV */
@media only screen and (max-width: 1000px) {
    h1{
        font-size: 1.5em;
    }
    h2{
        font-size: 1em;
    }
    p{
        font-size: 0.5em;
    }
    .img-consum{
        width: auto;
        height: auto;
        max-height: 69px;
    }
    .content-consum{
        padding: 0;
    }
    .content-project{
        height: 160px;
    }
    .img-project{
        width: 115px;
        height: 115px;
    }

}

/* tablets */
@media only screen and (max-width: 820px) {
    .chart-height{
        max-height: none;
    }
    .content-consum{
        width: 90%;
    }
    .content-project{
        width: 100%;
        height: auto;
    }
    .title-header{
        font-size: 0.8em;
        width: 64%;
        margin: 0 auto;
    }
    h1{
        font-size: 2em;
    }
    p{
        font-size: 1rem!important;
        line-height: 1.5rem!important;
    }
    .img-project{
        width: 150px;
        height: 150px;
    }
}

@media only screen and (max-width: 500px) {
    .circle-chart{
        max-width: 100px;
        max-height: 100px;
    }
    .img-project{
        width: 125px;
        height: 125px;
    }
    .App-header > p{
        width: 65%;
    }
    .button-calcula{
        padding: 0 41px;
    }
}
